import { getAssetUrl, useDeviceQuery } from "@/utils";
import {
  Box,
  Flex,
  Stack,
  rem,
  Title,
  Text,
  Container,
  Group,
  SimpleGrid,
} from "@mantine/core";
import { Trans, useTranslation } from "next-i18next";
import { Search } from "../../Search";
import IconMobile from "@/public/icons/icon-mobile.svg";
import IconTrain from "@/public/icons/icon-train.svg";
import IconQrScan from "@/public/icons/icon-qrscan.svg";
import Image from "next/image";
import { IconCircleCheckFilled } from "@tabler/icons-react";
import { Dispatch, RefObject, SetStateAction, useRef } from "react";
import { Carousel } from "@mantine/carousel";
import Autoplay from "embla-carousel-autoplay";
import { CustomNotice } from "@/components/CustomNotice";

const CarouselText = ({ children }: { children: React.ReactNode }) => {
  return (
    <Text
      className={`text-base md:text-4xl font-bold !text-white 
                  w-52 md:w-[440px] md:leading-[44px]`}
    >
      {children}
    </Text>
  );
};

const RenderImg = ({ img, priority }: { img: string; priority: boolean }) => {
  return (
    <Image
      alt="japan-shinkansen"
      src={getAssetUrl(img)}
      height={505}
      width={698}
      quality={100}
      priority={priority}
      className="object-cover h-full w-full object-left"
    />
  );
};

export function KeyVisual({
  setSearchRef,
  title,
  pcImg,
  spImg,
  area,
  subtitle,
  isCarousel,
}: {
  setSearchRef: Dispatch<SetStateAction<RefObject<HTMLDivElement> | null>>;
  title: string | React.ReactNode;
  pcImg: string;
  spImg: string;
  area?: string;
  subtitle?: string | false;
  isCarousel?: boolean;
}): JSX.Element {
  const [isMobile] = useDeviceQuery();
  const { t } = useTranslation();
  const autoplayPc = useRef(Autoplay({ delay: 5000 }));

  return (
    <>
      <Box className="bg-dark-4 w-full md:h-[560px]">
        <CustomNotice />
        <Container size={1440} className="px-0">
          <SimpleGrid cols={{ base: 1, md: 2 }}>
            <Stack className="h-full pt-20 pb-4 md:pt-12 md:pb-10 items-end px-5 md:px-10">
              <Stack
                align="flex-start"
                gap={rem(0)}
                w="100%"
                className="min-w-full lg:min-w-[450px] max-w-full lg:max-w-[600px] w-full"
              >
                <Box>
                  {title}
                  <Text className="block md:hidden !text-dark-7 !text-base">
                    {subtitle || t("top:kv.subtitle.0")}
                  </Text>
                  <Text className="hidden md:block !text-dark-7 !text-base">
                    {subtitle || t("top:kv.subtitle", { joinArrays: " " })}
                  </Text>
                </Box>
              </Stack>
              <Stack gap="xs" className="w-full flex md:hidden">
                <Flex className="gap-2 items-center">
                  <IconCircleCheckFilled size={16} className="!text-blue-9" />
                  <Stack gap={0}>
                    <Text className="font-bold !text-blue-9 text-sm">
                      {t("top:features.qr.title")}
                    </Text>
                  </Stack>
                </Flex>
                <Flex className="gap-2 items-center">
                  <IconCircleCheckFilled size={16} className="!text-blue-9" />
                  <Stack gap={0}>
                    <Text className="font-bold !text-blue-9 text-sm">
                      {t("top:features.support.title")}
                    </Text>
                  </Stack>
                </Flex>
                <Flex className="gap-2 items-center">
                  <IconCircleCheckFilled size={16} className="!text-blue-9" />
                  <Stack gap={0}>
                    <Text className="font-bold !text-blue-9 text-sm">
                      {t("top:features.booking.title")}
                    </Text>
                  </Stack>
                </Flex>
              </Stack>
              <Search setSearchRef={setSearchRef} area={area} />
            </Stack>
            {isCarousel ? (
              <Carousel
                withControls={false}
                withIndicators
                classNames={{
                  root: "h-full",
                  slide: "relative",
                  viewport: "h-full",
                  container: "h-full",
                  indicator: "h-2 w-2 data-[active]:bg-orange-9",
                  indicators: "gap-2.5 bottom-1.5",
                }}
                plugins={[autoplayPc.current]}
              >
                <Carousel.Slide>
                  <RenderImg img={isMobile ? spImg : pcImg} priority />
                </Carousel.Slide>
                <Carousel.Slide>
                  <RenderImg
                    img="/japan-shinkansen-customer-support.webp"
                    priority={false}
                  />
                  <Box className="absolute bottom-6 md:bottom-12 left-4 md:left-8">
                    <CarouselText>
                      <Trans
                        i18nKey="top:carousel.0"
                        components={{ a: <span className="!text-orange-3" /> }}
                      />
                    </CarouselText>
                    <Text className="text-base md:text-4xl font-bold !text-white mt-1 md:mt-3">
                      <Trans
                        i18nKey="top:carousel.1"
                        components={{
                          a: (
                            <a
                              href={`tel:${t("common:service.contact")}`}
                              className={`!text-orange-3 !text-sm md:!text-3xl border md:border-2 
                                            border-solid border-orange-3 rounded-md px-2`}
                            />
                          ),
                        }}
                        values={{ number: t("common:service.contact") }}
                      />
                    </Text>
                  </Box>
                </Carousel.Slide>
                <Carousel.Slide>
                  <RenderImg
                    img="/japan-shinkansen-fuji.webp"
                    priority={false}
                  />
                  <Box className="absolute bottom-6 md:bottom-12 left-4 md:left-8">
                    <CarouselText>
                      <Trans
                        i18nKey="top:carousel.2"
                        components={{ a: <span className="!text-orange-3" /> }}
                      />
                    </CarouselText>
                  </Box>
                </Carousel.Slide>
              </Carousel>
            ) : (
              <Box pos="relative" className="w-full">
                <RenderImg img={isMobile ? spImg : pcImg} priority />
              </Box>
            )}
          </SimpleGrid>
        </Container>
      </Box>
      <Box
        className="border-b border-solid border-dark-4 bg-custom-rose-white"
        visibleFrom="md"
      >
        <Container size="xl" className="py-10">
          <SimpleGrid cols={{ base: 1, md: 3 }}>
            <Group>
              <IconMobile />
              <Stack className="gap-0 w-4/5">
                <Title order={5} className="!text-blue-9 font-bold">
                  {t("top:features.booking.title")}
                </Title>
                <Text className="text-md !text-dark-8">
                  {t("top:features.booking.subtitle")}
                </Text>
              </Stack>
            </Group>
            <Group>
              <IconTrain />
              <Stack className="gap-0 w-4/5">
                <Title order={5} className="!text-blue-9 font-bold">
                  {t("top:features.tickets.title")}
                </Title>
                <Text className="text-md !text-dark-8">
                  {t("top:features.tickets.subtitle")}
                </Text>
              </Stack>
            </Group>
            <Group>
              <IconQrScan />
              <Stack className="gap-0 w-4/5">
                <Title order={5} className="!text-blue-9 font-bold">
                  {t("top:features.qr.title")}
                </Title>
                <Text className="text-md !text-dark-8">
                  {t("top:features.qr.subtitle")}
                </Text>
              </Stack>
            </Group>
          </SimpleGrid>
        </Container>
      </Box>
    </>
  );
}
